import React, {useEffect} from 'react'
import './notFoundPage.scss'

const NotFoundPage = (props)=>{
  const {redirect,redirectDelay,message} = props
  let redirectTimeout = null
  useEffect(()=>{
    if(redirect){
      redirectTimeout = setTimeout(() => {
        window.location.href = redirect
      }, redirectDelay)
    }
  },[])
  return(
    <div className="marketplace-page-not-found">
      {`${message?message+' ':''}${props.redirect?'Redirecting...':''}`}
    </div>
  )
}
export default NotFoundPage
