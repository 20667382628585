import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { useLocation } from 'react-router'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../../styles/toasts.scss'
import 'animate.css'
import './styles/marketplaceApp.scss'
import '../common/common-assets/stylesheets/index.scss'

import DealerCenterHeader from './headers/dealerCenterHeader'
import MarketplaceAppDefaultHeader from './headers/marketplaceAppDefaultHeader'
import MarketplaceAppCustomVdpHeader from './headers/marketplaceAppCustomVdpHeader'
import MarketplaceAppDefaultFooter from './footers/marketplaceAppDefaultFooter'
import './styles/marketplaceBody.scss'
import MobileRecommendationBanner from './banners/mobile-recommendation-banner/mobileRecommendationBanner'

import ModalContainer from './modals/modalContainer'
import LandingPage from './pages/landing-pages/landingPage'
import NewAndUsedLandingPage from './pages/landing-pages/newAndUsedLandingPage'
import SearchPage from './pages/search-page/searchPage'
import SignupLoginPage from './pages/signup-login-page/signupLoginPage'
import PasswordRecovery from './pages/password-recovery-page/passwordRecovery'
import VehicleDisplayPage from './pages/vehicle-display-page/vehicleDisplayPage'
import SavedVehiclePage from './pages/saved-vehicle-page/savedVehiclePage'
import AboutUs from './pages/aboutus-page/about-us'
import PrivacyPolicy from './pages/privacy-policy-page/privacyPolicy'
import TermsOfServicePage from './pages/terms-of-service-page/termsOfService'
import BuyingSmart from './pages/buying-smart-page/buyingSmart'
import DealerCenter from './pages/dealer-center/dealer-center'
import DemoSchedule from './pages/dealer-center/scheduleDemo'
import NotFoundPage from './pages/not-found-page/notFoundPage'
import AccountSettingsPage from './pages/account-settings/accountSettings'
import LogoutPage from './pages/logout-page/logoutPage'
import BlogPage from './pages/blog-page/blogPage'
import NewsPage from './pages/news-page/newsPage'
import CustomerTextOptInPage from './pages/customer-text-opt-in-page/customerTextOptInPage'

import SEARCH_PAGE_ACTIONS from './pages/search-page/state/searchPageActions'
import URL_HISTORY_ACTIONS from '../../state/actions/urlHistoryActions'
import { updateSiteTitle } from './marketplaceAppUtils'
import MARKETPLACE_APP_ACTIONS from './state/marketplaceAppActions'
import SAVED_VEHICLES_ACTIONS from './pages/saved-vehicle-page/state/savedVehiclePageActions'

function MarketplaceAppHeaderChild(props) {
  const { match, location, history } = props
  let pathSegments = location.pathname.split('/').filter((i) => i != '')
  if (pathSegments && pathSegments.length) {
    if (
      pathSegments[0] === 'vehicle-ext' ||
      pathSegments[0] === 'search-ext' ||
      (pathSegments[0] === 'vehicle' && pathSegments[1] === 'v3') ||
      window.location.hostname === 'www.ipacket.info'
    ) {
      return <React.Fragment />
    }
  }
  //Place Alternative Header Render Conditions Here
  // if (pathSegments[0] ==='marketplace' || pathSegments[0] === 'vehicle'
  //   ) {
  //     return <MarketplaceAppDefaultHeader />
  //   } else {
  //     return <DealerCenterHeader />
  //   }
  // }
  return <DealerCenterHeader />
}
const MarketplaceAppHeader = withRouter(MarketplaceAppHeaderChild)

function MarketplaceAppFooterChild(props) {
  const { match, location, history, token } = props
  //Place Alternative Footer Render Conditions Here
  let pathSegments = location.pathname.split('/').filter((i) => i != '')
  if ((
    pathSegments &&
    pathSegments.length &&
    (pathSegments[0] === 'vehicle-ext' ||
      (pathSegments[0] === 'vehicle' && pathSegments[1] === 'v3') ||
      pathSegments[0] === 'search-ext')
  ) || window.location.hostname === 'www.ipacket.info' ) {
    return <React.Fragment />
  }
  return <MarketplaceAppDefaultFooter token={token} />
}
const MarketplaceAppFooter = withRouter(MarketplaceAppFooterChild)

function MarketplaceApp(props) {
  const {
    getStoreSkin,
    getStoreConfiguration,
    stashUrlHistory,
    checkUserLoginStatus,
    loginStatusChecked,
    profileUpdateRequired,
    termsOfServiceRequired,
    getSavedVehicles,
    user,
    token
  } = props
  const location = useLocation()

  const urlParams = new URLSearchParams(window.location.search);
  const dealer_param = urlParams.get('dealer')
  // const pageInteractionRequired = profileUpdateRequired || termsOfServiceRequired

  useEffect(() => {
    checkUserLoginStatus()
    //Condition for checking on Store Skin and Configuration
    let url = window.location
    const extSiteId = new URLSearchParams(url.search).get('ext_site_id')

    if (extSiteId) {
      getStoreSkin(extSiteId)
      getStoreConfiguration(extSiteId)
    }
  }, [])

  useEffect(() => {
    if (user && token) {
      getSavedVehicles()
    }
  }, [user, token])

  useEffect(() => {
    if (location.pathname === '/marketplace/new-cars') {
      updateSiteTitle('Shop New Cars - iPacket')
    } else if (location.pathname === '/marketplace/used-cars') {
      updateSiteTitle('Shop Used Cars - iPacket')
    } else if (location.pathname === '/about-us') {
      updateSiteTitle('About Us - iPacket')
    } else if (location.pathname === '/buying-smart') {
      updateSiteTitle('Education Center - iPacket')
    } else {
      updateSiteTitle()
    }
    stashUrlHistory(window.location)
  }, [location])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location.pathname])
  if (loginStatusChecked) {
    return (
      <>
        <ModalContainer />
        <div className="marketplace">
          <MarketplaceAppHeader />
          <div className="marketplace-body">
            <Switch>
              {profileUpdateRequired ? (
                <Route
                  path="*"
                  render={() => <AccountSettingsPage forceEditing={true} />}
                />
              ) : null}
              {termsOfServiceRequired ? (
                <Route
                  path="*"
                  render={() => <TermsOfServicePage formMode={true} />}
                />
              ) : null}

              {/*NEEDS TO BE REMOVED ONCE VDP IS FULLY FLIPPED*/}
              {/*NEEDS TO BE REMOVED ONCE VDP IS FULLY FLIPPED*/}
              {/*NEEDS TO BE REMOVED ONCE VDP IS FULLY FLIPPED*/}
              {/*NEEDS TO BE REMOVED ONCE VDP IS FULLY FLIPPED*/}
              {
                (window.location.hostname === 'www.ipacket.info'|| window.location.hostname === 'ipacket.info')?(
                  <Route
                    path="/:id"
                    exact
                    component={(props) => {
                      return (
                        <VehicleDisplayPage
                          id={props.match.params.id}
                          externalVdp={true}
                          sharePage={true}
                        />
                      )
                    }}
                  />

                ):null
              }
              {
                (window.location.hostname === 'www.ipacket.info'|| window.location.hostname === 'ipacket.info')?(
                  <Route path="*" component={(props)=><AboutUs/>} />
                ):null
              }
              {/*NEEDS TO BE REMOVED ONCE VDP IS FULLY FLIPPED*/}
              {/*NEEDS TO BE REMOVED ONCE VDP IS FULLY FLIPPED*/}
              {/*NEEDS TO BE REMOVED ONCE VDP IS FULLY FLIPPED*/}
              {/*NEEDS TO BE REMOVED ONCE VDP IS FULLY FLIPPED*/}
              {/*NEEDS TO BE REMOVED ONCE VDP IS FULLY FLIPPED*/}


              <Route exact path="/" component={DealerCenter} />
              <Route
                path="/marketplace/new-cars"
                render={() => {
                  return <NewAndUsedLandingPage page="newCars" />
                }}
              />
              <Route
                path="/marketplace/used-cars"
                render={() => {
                  return <NewAndUsedLandingPage page="usedCars" />
                }}
              />

              {/*SRP PAGES -- START*/}
              <Route
                path="/search-ext/:zipcode/:condition/:make/:model"
                component={(props) => {
                  return (
                    <SearchPage
                      externalSrp={true}
                      urlZipcode={props.match.params.zipcode}
                      urlCondition={props.match.params.condition}
                      urlMake={props.match.params.make}
                      urlModel={props.match.params.model}
                    />
                  )
                }}
              />
              <Route
                path="/search/:zipcode/:condition/:make/:model"
                component={(props) => {
                  return (
                    <SearchPage
                      urlZipcode={props.match.params.zipcode}
                      urlCondition={props.match.params.condition}
                      urlMake={props.match.params.make}
                      urlModel={props.match.params.model}
                    />
                  )
                }}
              />
              {/*SRP PAGES -- END*/}

              {token ? null : (
                <Route path="/marketplace/signup">
                  <SignupLoginPage />
                </Route>
              )}
              {token ? null : (
                <Route path="/marketplace/login">
                  <SignupLoginPage login={true} />
                </Route>
              )}
              <Route path="/marketplace/password-reset" component={PasswordRecovery} />

              {/*VDP PAGES -- START*/}
              <Route
                path="/vehicle/v3/vdp/:id"
                component={(props) => {
                  return (
                    <VehicleDisplayPage
                      id={props.match.params.id}
                      externalVdp={true}
                      sharePage={true}
                    />
                  )
                }}
              />
              <Route
                path="/vehicle/v3/insight/:id"
                component={(props) => {
                  return (
                    <VehicleDisplayPage
                      id={props.match.params.id}
                      externalVdp={true}
                      sharePage={true}
                      insightsView={true}
                    />
                  )
                }}
              />
              <Route
                path="/vehicle-ext/:id"
                component={(props) => {
                  return (
                    <VehicleDisplayPage
                      id={props.match.params.id}
                      externalVdp={true}
                    />
                  )
                }}
              />
              <Route
                path="/vehicle/:id"
                component={(props) => {
                  return <VehicleDisplayPage id={props.match.params.id} />
                }}
              />
              {/*VDP PAGES -- END*/}

              <Route path="/about-us" component={AboutUs} />
              <Route path="/careers" component={AboutUs} />

              <Route path="/privacypolicy" component={PrivacyPolicy} />
              <Route path="/buying-smart" component={BuyingSmart} />
              <Route path="/schedule-demo" component={DemoSchedule} />
              <Route path="/schedule" component={DemoSchedule} />
              <Route path="/marketplace" component={LandingPage} />

              {token ? (
                <Route path="/savedvehicle" component={SavedVehiclePage} />
              ) : null}
              {token ? (
                <Route
                  path="/account-settings"
                  component={AccountSettingsPage}
                />
              ) : null}
              <Route
                path="/terms-of-service"
                render={() => <TermsOfServicePage formMode={false} />}
              />
              <Route
                path="/blog"
                component={BlogPage}/>
              />
              <Route
                path="/news"
                component={NewsPage}/>
              />

              <Route path="/logout" component={LogoutPage} />
              {(dealer_param && dealer_param.length)?
                <Route path="/customer-text-opt-in"
                  component={()=><CustomerTextOptInPage dealerName={dealer_param}/>
                  }
                />
                :null}
              <Route path="*" component={(props)=><NotFoundPage message={'Page Not Found.'}redirect={'/'} redirectDelay={2000}/>} />
            </Switch>
            <ToastContainer
              toastClassName="default-toast"
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
              hideProgressBar={true}
              draggable
            />
          </div>
          <MarketplaceAppFooter token={token} />
          <MobileRecommendationBanner />
        </div>
      </>
    )
  }
  return <React.Fragment></React.Fragment>
}

const mapDispatchToProps = {
  ...SAVED_VEHICLES_ACTIONS.dispatchActions,
  ...URL_HISTORY_ACTIONS.dispatchActions,
  ...MARKETPLACE_APP_ACTIONS.dispatchActions,
  ...SEARCH_PAGE_ACTIONS.dispatchActions
}

const mapStateToProps = (state) => ({
  loginStatusChecked: state.userProfile.loginStatusChecked,
  ...state.userProfile
})
export default connect(mapStateToProps, mapDispatchToProps)(MarketplaceApp)
